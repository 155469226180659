import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import { commonFR } from "../translations/common/index";
import { constantsFR } from "@/translations/constants";
import { settingsFR } from "../translations/settings";

const messages = {
  fr: {
    auth: require("../translations/auth.fr.json"),
    accounts: require("../translations/accounts.fr.json"),
    collections: require("../translations/collections.fr.json"),
    components: require("../translations/components.fr.json"),
    dashboard: require("../translations/dashboard.fr.json"),
    suppliers: require("../translations/suppliers.fr.json"),
    products: require("../translations/products.fr.json"),
    "projects-impact": require("../translations/projects-impact.fr.json"),
    profile: require("../translations/profile.fr.json"),
    studies: require("../translations/studies.fr.json"),
    links: require("../translations/links.fr.json"),
    materials: require("../translations/materials.fr.json"),
    "preview-materials": require("../translations/preview-materials.fr.json"),
    common: commonFR,
    settings: settingsFR,
    constants: constantsFR,
  },
};

export default new VueI18n({
  locale: "fr",
  fallbackLocale: "fr",
  messages: messages,
  silentTranslationWarn: true,
});
