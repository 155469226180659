const mainSettingsFR = require("./settings.fr.json");
const rawMaterialsFR = require("./raw-materials.fr.json");
const fmAccountsFR = require("./fm-accounts.fr.json");
const documentsGeneratorFR = require("./documents-generator.fr.json")

export const settingsFR = {
  ...mainSettingsFR,
  "fm-accounts": fmAccountsFR,
  "raw-materials": rawMaterialsFR,
  "documents-generator": documentsGeneratorFR
}