<template>
  <v-app>
      <Topbar v-if="!$route.meta.isPublic"/>
      <PageHeadHandler />
      <Toast />
      <Sidebar v-if="$session.exists() && !$route.meta.isPublic"/>
      <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
    // Footer: () => import("@/components/Common/Bars/Footer"),
    Toast: () => import("@/components/Common/Toast"),
    PageHeadHandler: () => import("./components/Common/PageHeadHandler"),
    Sidebar: () => import("@/components/Common/Bars/Sidebar"),
    Topbar: () => import("@/components/Common/Bars/Topbar"),
  },

  data: () => ({
    //
  }),

  watch:{
    $route (to, from)
    {
      if (this.$matomo)
      {
        this.$matomo.setCustomUrl(location.href);
        this.$matomo.setDocumentTitle(to.path);
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/_styles.scss";

html {
  overflow-y: auto !important;
  overflow-x: auto !important;
}

body {
  margin: 0 auto;
  padding: 0;
  overflow: hidden !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: flex;
}

</style>
