const mainConstantsFR = require("./constants.fr.json");
const mainConstantsEN = require('./constants.en.json');
const certificationsFR = require('./certifications.fr.json');
const certificationsEN = require('./certifications.en.json');
const rawMaterialsFR = require('./raw_materials.fr.json');
const rawMaterialsEN = require('./raw_materials.en.json');

export const constantsFR = {
  ...mainConstantsFR,
  certifications: certificationsFR,
  rawMaterials: rawMaterialsFR
}

export const constantsEN = {
  ...mainConstantsEN,
  certifications: certificationsEN,
  rawMaterials: rawMaterialsEN
}
