function initialState() {
  return {
    img: null,
    lang: null
  }
}

const profile =
{
  namespaced: true,

  state: initialState,

  getters:
  {
    getImg: state => {
      return state.img;
    },

    getLang: state => {
      return state.lang;
    }
  },

  mutations:
  {
    setImg: (state, payload = null) => {
      state.img = payload;
    },

    setLang: (state, payload) => {
      state.lang = payload;
    },

    reset(state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  }
};

export default profile;