import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import store from './store'
import vuetify from './plugins/vuetify';

import VueSession from "vue-session";
import {HTTP} from "@/plugins/http-common";
import i18n from "@/plugins/i18n";
import VueLuxon from "vue-luxon";
import VueClipboard from 'vue-clipboard2'
import VueCurrencyFilter from 'vue-currency-filter'
import VueRamda from "vue-ramda";
import VueGtag from "vue-gtag";
import VueShowdown from 'vue-showdown';
import VueMatomo from 'vue-matomo'

Vue.filter("truncate", function(text, length = 40, clamp = "...") {
  if (text)
    return `${text.slice(0, length)} ${(length < text.length ? clamp : '')}`;
  return "";
});

Vue.use(VueClipboard);
Vue.use(VueSession, {persist: true});
Vue.use(VueLuxon);
Vue.use(VueRamda);
//Vue.use(vueMoment, {moment});

Vue.use(VueCurrencyFilter, {
  symbol : '',
  thousandsSeparator: ' ',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: '',
  symbolSpacing: true
});

if (process.env.VUE_APP_GTAG) {
  Vue.use(VueGtag, {
    config: {id: process.env.VUE_APP_GTAG}
  });
}

Vue.use(VueShowdown);

if (process.env.NODE_ENV === "production") {
  Vue.use(VueMatomo,
  {
    router: router,
    host: 'https://fairlymade.matomo.cloud',
    siteId: 2,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    debug: false
  });
}

Vue.config.productionTip = false
Vue.prototype.$http = HTTP;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueSession,
  render: h => h(App)
}).$mount('#app')
