import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from "@/plugins/router";

import alert from "@/store/alert";
import pageHead from "@/store/pageHead";
import toast from "@/store/toast";
import profile from "@/store/profile";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    sidebar: true
  },
  getters: {
    getToggleSidebar: state => {
      return state.sidebar;
    }
  },
  mutations: {
    setToggleSidebar(state, toggle) {
      state.sidebar = toggle;
    },

    toggleSidebar(state) {
      state.sidebar = !state.sidebar;
    }
  },
  actions: {
    logout({dispatch, rootGetters, commit}) {
      console.log("LOGOUT")
      this._vm.$session.destroy();
      commit("alert/reset");
      //commit("data/reset");
      commit("pageHead/reset");
      commit("profile/reset");
      commit("toast/reset");
      router.push({name: "Login"});
    }
  },

  modules: {
    alert,
    pageHead,
    profile,
    toast
  }
})
