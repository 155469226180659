import i18n from "../plugins/i18n";

function initialState() {
  return {
    toggle: false,
    text: "",
    color: "",
    type: "",
    dismissible: true
  }
}

const alert = {

  namespaced: true,

  state: initialState(),

  mutations: {

    showError: function (state, text) {

      state.text = text;
      state.color = "error";
      state.toggle = true;
    },

    showSuccess: function (state, text) {
      state.text = text;
      state.color = "success";
      state.toggle = true;
    },

    showErrorAxios: function(state, payload) {
      let text = null;

      if (payload && payload.response && payload.response.status && i18n.t('common.errors').hasOwnProperty(payload.response.status)) {

        if (payload.response.data && i18n.t(`common.errors.${payload.response.status}`).hasOwnProperty(payload.response.data.type))
          text = i18n.t(`common.errors.${payload.response.status}.${payload.response.data.type}`);
        else
          text = i18n.t(`common.errors.${payload.response.status}`);
      }
      else
        text = i18n.t(`common.errors.500`);

      state.color = "error";
      state.text = text;
      state.toggle = true;
    },

    hideAlert: function (state) {
      state.toggle = false;
    },

    reset (state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  }
};

export default alert;