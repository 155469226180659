<template>
  <div style="min-height:calc(100vh);">

    <v-row no-gutters>

      <!-- Topbar -->
      <v-col cols="12">

      </v-col>

      <v-col cols="12">

        <v-main class="bg-main" fill-height style="min-height: calc(100vh - 80px);" >
          <router-view :key="$route.path" />
        </v-main>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  export default {

    name: "AppLayout",

    components: {

    },

    mounted() {
      window.scrollTo(0, 0)
    },
  }
</script>

<style scoped>
  .bg-main {
    background-color: #FFF;
    height: 100%;
  }
  .word-wrap {
    word-break: break-word;
  }

  .main_card-div {
    height: 150px;
    margin-top: 20px;
  }

  .detail-text-label {
    color: rgba(0, 0, 0, 0.55);
  }

  .detail-text-value {
    color: rgba(0, 0, 0, 0.80);
  }
</style>