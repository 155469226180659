import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "./i18n";

import AppLayout from "../views/AppLayout";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/error/:status",
    name: "error",
    component: () => import(/* webpackChunkName: "error" */ "@/views/Error"),
    meta: { isPublic: true },
  },

  {
    path: "/auth/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/Auth/Login.vue"),
    meta: { isPublic: true },
  },

  {
    path: "/public/fm-signature-generator",
    name: "FMSignatureGenerator",
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/Public/FMSignatureGeneratorView"
      ),
    meta: { isPublic: true },
  },

  {
    path: "/",
    name: "layout",
    redirect: "/dashboard",
    component: AppLayout,
    children: [
      {
        path: "dashboard",
        name: "Home",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/Dashboard/MainDashboard"
          ),
      },
      {
        path: "accounts",
        name: "Accounts",
        component: () =>
          import(
            /* webpackChunkName: "accounts" */ "@/views/Accounts/AccountsView"
          ),
      },
      {
        path: "accounts/:id",
        name: "Account",
        component: () =>
          import(
            /* webpackChunkName: "accounts" */ "@/views/Accounts/AccountView"
          ),
      },
      {
        path: "suppliers",
        name: "Suppliers",
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ "@/views/Suppliers/SuppliersView"
          ),
      },
      {
        path: "suppliers/:id",
        name: "Supplier",
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ "@/views/Suppliers/SupplierView"
          ),
      },
      {
        path: "collections",
        name: "Collections",
        component: () =>
          import(
            /* webpackChunkName: "collections" */ "@/views/Collections/CollectionsView"
          ),
      },
      {
        path: "collections/:id",
        name: "Collection",
        component: () =>
          import(
            /* webpackChunkName: "collections" */ "@/views/Collections/CollectionView"
          ),
      },
      {
        path: "studies",
        name: "Studies",
        component: () =>
          import(
            /* webpackChunkName: "studies" */ "@/views/Studies/StudiesView"
          ),
      },
      {
        path: "studies/:id",
        name: "Study",
        component: () =>
          import(/* webpackChunkName: "studies" */ "@/views/Studies/StudyView"),
      },
      {
        path: "projects-impact",
        name: "ProjectsImpact",
        component: () =>
          import(
            /* webpackChunkName: "projects-impact" */ "@/views/ProjectsImpact/ProjectsImpactView"
          ),
      },
      {
        path: "projects-impact/:id",
        name: "ProjectImpact",
        component: () =>
          import(
            /* webpackChunkName: "projects-impact" */ "@/views/ProjectsImpact/ProjectImpactView"
          ),
      },
      {
        path: "components",
        name: "Components",
        component: () =>
          import(
            /* webpackChunkName: "components" */ "@/views/Components/ComponentsView"
          ),
      },
      {
        path: "components/:id",
        name: "Component",
        component: () =>
          import(
            /* webpackChunkName: "components" */ "@/views/Components/ComponentView"
          ),
      },
      {
        path: "materials",
        name: "Materials",
        component: () =>
          import(
            /* webpackChunkName: "materials" */ "@/views/Materials/MaterialsView"
          ),
      },
      {
        path: "materials/:id",
        name: "Material",
        component: () =>
          import(
            /* webpackChunkName: "materials" */ "@/views/Materials/MaterialView"
          ),
      },
      {
        path: "links",
        name: "Links",
        component: () =>
          import(/* webpackChunkName: "links" */ "@/views/Links/LinksView"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "@/views/Profile/ProfileView"
          ),
      },

      // SETTINGS
      {
        path: "settings",
        name: "SettingsPanel",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/Settings/SettingsView"
          ),
      },
      {
        path: "settings/members",
        name: "Settings/Members",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/Settings/Members/MembersView"
          ),
      },
      {
        path: "settings/members/:id",
        name: "Settings/Member",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/Settings/Members/MemberView"
          ),
      },
      {
        path: "settings/raw-materials",
        name: "Settings/RawMaterials",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/Settings/RawMaterials/RawMaterialsView"
          ),
      },
      {
        path: "settings/raw-materials/:id",
        name: "Settings/RawMaterial",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/Settings/RawMaterials/RawMaterialView"
          ),
      },
      {
        path: "settings/api-tokens",
        name: "Settings/ApiTokens",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/views/Settings/ApiTokens/ApiTokensView"
          ),
      },
    ],
  },
  {
    path: "*",
    name: "not_found",
    meta: { isPublic: true },
    redirect: "/error/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("alert/hideAlert");

  if (to.query.lang && to.query.lang !== router.app.$session.get("lang"))
    router.app.$session.set("lang", to.query.lang);

  if (i18n.locale !== router.app.$session.get("lang")) {
    i18n.locale = router.app.$session.get("lang");
  }

  if (
    !to.matched.some((record) => record.meta.isPublic) &&
    !router.app.$session.exists()
  )
    return next({ name: "Login" });

  return next();
});

export default router;
